<template>
  <div>
    <h3>{{$t('products.productslist')}}</h3>
    <el-menu
      :default-active="$route.path"
      router
      :default-openeds="defaultOpeneds"
    >
      <el-submenu v-for="item in category" :key="item.id" :index="item.id+''">
        <template slot="title">
          <i class="el-icon-office-building"></i>
          <span>{{$i18n.locale == 'zh-cn'? item.name: item.englishname}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="i in item.children"
            :key="i.id"
            :index="'/products/' + i.id"
          >
            <i class="el-icon-school"></i>
            <span>{{$i18n.locale == 'zh-cn'? i.name : i.englishname}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>


<script>
export default {
  name: 'LeftMenu',
  data() {
    return {
      category: [
        
      ]
    }
  },
  computed: {
    defaultOpeneds() {
      var narray = []
      this.category.forEach(function(value) {
        narray.push( value.id+'')
      })
      return narray
    }
  },
  methods:{
    
  },
  created(){
    this.$http.get('/category/getallcat').then(res=>this.category=res.data.data)
  }
}
</script>
<style scoped>
</style>