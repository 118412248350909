<template>
  <div>
    <el-row :gutter="12">
      <el-col :span="6">
        <LeftMenu />
      </el-col>
      <el-col :span="18">
        <el-row :gutter="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              :to="{path: '/products/'+ category.cparentid}"
            >{{$i18n.locale == 'zh-cn' ? category.cparentnamechn : category.cparentnameeng}}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path: '/products/'+ product.CategoryID}">{{$i18n.locale == 'zh-cn' ? category.cnamechn : category.cnameeng}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{$i18n.locale == 'zh-cn' ? product.ProductNameChn : product.ProductNameEng}} {{product.ProductSizeChn}}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-row>
        <el-row>
          <div style="margin: 0 auto; text-align:center">
            <el-image
              v-for="item in (product.ProductImgUrl || '').split(' ')"
              :src="$store.state.baseurl+'/Images/'+item"
              :key="item"
              class="image"
              :preview-src-list="srcList"
            />
          </div>
        </el-row>
        <el-tabs type="border-card" style="margin-top:12px;" value="first" v-if="product.ViewTags">
          <el-tab-pane :label="$t('product.productdesc')" name="first">
            <div>
              <el-row :gutter="24">
                <el-col :span="12">
                  <div v-html="zyxn"></div>
                </el-col>
                <el-col :span="12">
                  <div v-html="yyly"></div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('product.spec')" name="second">
            <div v-html="$i18n.locale == 'zh-cn' ? product.ProductSpecChn : product.ProductSpecEng"></div>
          </el-tab-pane>
          <el-tab-pane :label="$t('product.speedcurves')" name="third">
            <div v-show="$i18n.locale == 'zh-cn' ?  product.CurveDrawingChn!=null : product.CurveDrawingEng!=null">
              <img
                v-for="item in (($i18n.locale == 'zh-cn' ? product.CurveDrawingChn : product.CurveDrawingEng) || '').split(' ')"
                :src="$store.state.baseurl+'/Images/'+item"
                :key="item"
                width="100%"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('product.dimensiondrawing')" name="fourth">
            <div v-show="$i18n.locale == 'zh-cn' ? product.ProductDrawingChn!=null : product.ProductDrawingEng!=null">
              <img
                v-for="item in (($i18n.locale == 'zh-cn' ? product.ProductDrawingChn : product.ProductDrawingEng) ||'').split(' ')"
                :src="$store.state.baseurl+'/Images/'+item"
                :key="item"
                class="borderimg"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-row v-else>
          <el-tabs type="border-card" style="margin-top:12px;" value="first">
          <el-tab-pane :label="$t('product.productdesc')" name="first">
          <div>
            <img
              v-for="item in (($i18n.locale == 'zh-cn' ? product.ProductDrawingChn :product.ProductDrawingEng) ||'').split(' ')"
              :src="$store.state.baseurl+'/Images/'+item"
              :key="item"
              width="250px"
            />
          </div>
          <div v-html="$i18n.locale == 'zh-cn' ? product.ProductSpecChn : product.ProductSpecEng" style="margin-top:15px;"></div>
          </el-tab-pane></el-tabs>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>      


<script>
import LeftMenu from '@/components/LeftMenu.vue'
export default {
  name: 'Product',
  components: {
    LeftMenu
  },
  data() {
    return {
      product: {},
      srcList: [],
      category: {}
    }
  },
  created() {
    this.$http
      .get('/product/getproduct?id=' + this.$route.params.id)
      .then(res => {
        this.product = res.data.data
        var list = (res.data.data.ProductImgUrl || '').split(' ')
        list.forEach(element => {
          this.srcList.push(this.$store.state.baseurl + '/Images/' + element)
        })
        this.$http
          .get('/category/GetCategory?id=' + res.data.data.CategoryID)
          .then(res => {
            this.category = res.data.data
          })
      })
  },
  computed: {
    zyxn() {
      return ((this.$i18n.locale == 'zh-cn' ? this.product.BriefDescChn : this.product.BriefDescEng) || '').split('*#$')[0]
    },
    yyly() {
      return ((this.$i18n.locale == 'zh-cn' ? this.product.BriefDescChn : this.product.BriefDescEng) || '').split('*#$')[1]
    }
  }
}
</script>
<style scoped>
>>> h2 {
  padding-left: 12px;
  border-radius: 5px;
  background-image: linear-gradient(to right, lightgray, white);
}
>>> h3 {
  color: #ffa500;
}
.image {
  width: 250px;
  height: 250px;
}
.borderimg{
  border: 1px solid black;
  margin: 15px;
  width:98%;
}
</style>
